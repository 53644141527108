import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  ClipboardCheck,
  Clipboard,
  ArrowDown,
  ArrowUp,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import strings from "../../i18n/strings";
import { PasswordField } from "../../shared/components/PasswordField";
import { SafePasswordDto } from "../../shared/models";

const DetailRow = (props: {
  label: string;
  value: string;
  isPassword?: boolean;
  isUrl?: boolean;
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    if (props.value) {
      return window.navigator.clipboard.writeText(props.value).then(
        function () {
          /* clipboard write success */
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        },
        function () {
          /* clipboard write failed */
          setCopied(false);
        }
      );
    }
  };

  const mouseOver = (isLeaving: boolean) => {
    setShowIcon(!isLeaving);

    if (!isLeaving && isMobile) {
      setTimeout(() => setShowIcon(false), 2000);
    }
  };

  return (
    <React.Fragment>
      <Row
        className="my-1"
        onMouseOver={() => mouseOver(false)}
        onMouseLeave={() => mouseOver(true)}>
        <Col className="flex-grow-0 fw-bold">{props.label}</Col>
        <Col className="flex-grow-1 flex-nowrap d-flex flex-row align-items-center">
          <div className="m-0 p-0 d-flex flex-row align-items-center">
            {props.isPassword && (
              <PasswordField
                editable={false}
                maxLength={255}
                className="border-0 flex-grow-1"
                value={props.value}
              />
            )}
            {!props.isPassword && !props.isUrl && (
              <span className="cut-text-simple flex-grow-1">{props.value}</span>
            )}
            {!props.isPassword && props.isUrl && (
              <a
                className="flex-grow-1"
                target="_blank"
                rel="noreferrer"
                href={props.value}>
                {props.value}
              </a>
            )}
            {showIcon && !copied && (
              <Clipboard
                role="button"
                className="ms-1 fs-5"
                title="Copy"
                onClickCapture={handleCopyClick}
                onClick={handleCopyClick}
              />
            )}
            {copied && (
              <ClipboardCheck className="alert-success font-weight-bolder ms-1 fs-4" />
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const PassCard = (props: {
  item: SafePasswordDto;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const { item: x, onEdit, onDelete } = props;

  const [expandDetails, setExpandDetails] = useState(false);

  return (
    <Card className="my-1 border-2">
      <Card.Header className="fs-5 fw-bold p-1">
        <div className="m-0 p-0 d-flex flex-row align-items-center">
          <span className="flex-grow-1">
            {" "}
            {x.account}
            {x.details && (
              <React.Fragment>
                {expandDetails && (
                  <ArrowUp
                    className="ms-1 bg-light"
                    role={"button"}
                    title="Details"
                    onClick={() => setExpandDetails(!expandDetails)}
                  />
                )}
                {!expandDetails && (
                  <ArrowDown
                    className="ms-1 bg-light"
                    role={"button"}
                    title="Details"
                    onClick={() => setExpandDetails(!expandDetails)}
                  />
                )}
              </React.Fragment>
            )}
          </span>

          <div>
            <Button
              variant="primary"
              size="sm"
              className="text-start  me-1"
              onClick={() => onEdit()}>
              <PencilSquare />
            </Button>

            <Button
              variant="danger"
              size="sm"
              className="text-start  me-1"
              onClick={() => onDelete()}>
              <Trash />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="fs-6 p-1">
        <Container fluid className="m-0 p-0 d-flex flex-row">
          <div className="flex-grow-1">
            <DetailRow label={strings.pass.username} value={x.username} />
            <DetailRow
              label={strings.pass.password}
              value={x.password}
              isPassword
            />
            {x.url && (
              <DetailRow isUrl label={strings.pass.url} value={x.url ?? ""} />
            )}
          </div>
        </Container>
      </Card.Body>
      {expandDetails && (
        <Card.Footer
          className="p-1"
          style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
          {x.details}
        </Card.Footer>
      )}
    </Card>
  );
};

export default PassCard;
