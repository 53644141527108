import React from 'react';
import { useLocalize } from '../shared/hooks/localize';


const NotReady = () => {

    const { strings } = useLocalize();

    return (
        <div>
            <p>{strings.shared.underConstruction}</p>
        </div>
    );
};

export default NotReady;
