import { IAppStrings } from "./stringsDef";

export const deStrings: IAppStrings = {
  app: {
    title: "HUCOM SafePass",
  },
  menu: {
    home: "Home",
    email: "E-Mail",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Datenschutz",
    imprint: "Impressum",
    passwords: "Passwörter",
  },
  account: {
    signin: "Anmelden",
    signout: "Abmelden",
    processingToken: "Benutzersession wird initialisiert...",
    processingSignout: "Die Benutzersession wird beendet...",
    loginTitle: "Anmeldung",
    email: "E-Mail",
    emailorUsername: "E-Mail oder Benutzername",
    password: "Kennwort",
    emailRequired: "E-Mail ist erforderlich!",
    passwordRequired: "Password ist erforderlich!",
    signInViaGoogle: "Anmelden mit Google",
    signInViaFacebook: "Anmelden mit Facebook",
    signInViaMicrosoft: "Anmelden mit Microsoft",
    wrongPassOrUsername: "Benutzername/E-Mail oder Passwort ist falsch!",
  },
  pass: {
    noItem: "Es gibt kein Passwort!",
    account: "Konto",
    url: "Url",
    createdDate: "Erstellungsdatum",
    updatedDate: "Aktualisierungsdatum.",
    confirmDelete: "Sind Sie sicher, dass Sie das Passwort löschen möchten?",
    addNewPassword: "Neus Passwort",
    details: "Details",
    password: "Passwort",
    masterPassword: "Master-Passwort",
    username: "Benutzername",
    downloadPassList: "Herunterladen",
    uploadPassList: "Hocladen",
    importedPassMessage: "Anzahl der importierten Passwörter: {0}",
    wrongMasterpass: "Falsches Master-Passwort!",
    changeMasterpass: "Master-Passwort ändern",
    noUpdateOnImport: "Beim Import keine Änderungen erkannt!"
  },
  footer: {
    copyright: "Alle Rechte vorbehalten ®",
  },
  shared: {
    copy: "Kopieren",
    show: "Zeigen",
    hide: "Verstecken",
    add: "Hinzufügen",
    or: "oder",
    save: "Speichern",
    cancel: "Abbrechen",
    delete: "Löschen",
    edit: "Bearbeiten",
    enter: "Eingeben",
    import: "Importieren",
    export: "Exportieren",
    keepassExport:"Exportieren (Keepass)",
    csvExport: "Exportieren (CSV)",
    underConstruction: "Im Aufbau!",
    technicalError:
      "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal!",
  },
};
