import { toast } from "react-toastify";
 

export default class notifyHelper {
  public static error(message: string) {
    toast(message, { type: "error" });
  }

  public static info(message: string) {
    toast(message, { type: "info" });
  } 
  
  public static warn(message: string) {
    toast(message, { type: "warning" });
  }
}
