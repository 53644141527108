import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/Laoder";
import { ApplicationPaths } from "../configs";
import { useLocalize } from "./localize";

export interface IAppAuthContext {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isInitialized: boolean;
  user: User | null;
  logout: () => void;
  login: (email: string, pass: string, returnUrl?: string) => Promise<boolean>;
  loginByGoogle: (returnUrl?: string) => void;
}

export const authContext = React.createContext<IAppAuthContext>(
  {} as IAppAuthContext
);

export const AuthProvider = (props: any) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { localizePath } = useLocalize();

  const [interState, setInterState] = useState<{
    user: User | null;
    isInitialized: boolean;
    isAuthenticating: boolean;
  }>({ user: null, isAuthenticating: false, isInitialized: false });

  const { user, isInitialized, isAuthenticating } = interState;

  const logout = async () => {
    await signOut(auth);
    navigate(localizePath(ApplicationPaths.Login), { replace: true });
  };

  const loginByGoogle = (returnUrl?: string) => {
    setInterState((prev) => ({
      ...prev,
      isAuthenticating: true,
      user: null,
    }));

    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        setInterState((prev) => ({
          ...prev,
          isAuthenticating: false,
          user: response.user,
        }));

        navigate(returnUrl ?? localizePath("/"));
      })
      .catch((error) => {
        console.error("login errror", error);

        setInterState((prev) => ({
          ...prev,
          isAuthenticating: false,
          user: null,
        }));
      });
  };

  const login = async (
    email: string,
    password: string,
    returnUrl?: string
  ): Promise<boolean> => {
    if (!email || !password) {
      return false;
    }

    setInterState((prev) => ({
      ...prev,
      isAuthenticating: true,
      user: null,
    }));

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setInterState((prev_1) => ({
        ...prev_1,
        isAuthenticating: false,
        user: userCredential.user,
      }));

      navigate(returnUrl ?? localizePath("/"));
      return true;
    } catch (error) {
      setInterState((prev_2) => ({
        ...prev_2,
        isAuthenticating: false,
        user: null,
      }));
      return false;
    }
  };

  useEffect(() => {
    const authCheck = onAuthStateChanged(auth, (user) => {
      setInterState((prev) => ({
        ...prev,
        isAuthenticating: false,
        isInitialized: true,
        user: user,
      }));
    });

    return () => authCheck();
  }, [auth]);

  return (
    <authContext.Provider
      value={{
        isAuthenticated: !!user,
        isInitialized,
        isAuthenticating,
        user,
        logout,
        login,
        loginByGoogle,
      }}>
      {isInitialized && props.children}
      {!isInitialized && <Loader visible />}
    </authContext.Provider>
  );
};

export const useAppAuth = (): IAppAuthContext => {
  const context = React.useContext(authContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
