import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from "react-cookie"; 
import reportWebVitals from './reportWebVitals';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-bootstrap';
import { LocalizeProvider } from './shared/hooks/localize';
import { AuthProvider } from './shared/hooks/auth';

import { appInstance } from './shared/configs';

appInstance.initialize();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <LocalizeProvider>
          <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
          >
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeProvider>
        </LocalizeProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
