import { Field, Formik, useFormik } from "formik";
import * as yup from "yup";
import React, { useImperativeHandle, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { passService } from "../../services/PassService";
import { useLocalize } from "../../shared/hooks/localize";
import { SafePasswordDto } from "../../shared/models";
import { PasswordField } from "../../shared/components/PasswordField";

const modelProp = (x: keyof SafePasswordDto) => x.toString();

interface DialogState {
  visible: boolean;
  data: SafePasswordDto | null;
}

const PassDialog = React.forwardRef(
  (
    props: {
      onDelete: (item: SafePasswordDto) => Promise<any>;
      onSave: (item: SafePasswordDto | null) => Promise<any>;
      onClose?: () => Promise<any>;
      masterPw: string;
    },
    ref
  ) => {
    const { strings } = useLocalize();

    const [dialogState, setDialogState] = useState<DialogState>({
      visible: false,
      data: null,
    });

    const { onDelete, onSave, onClose, masterPw } = props;

    const { visible, data } = dialogState;

    const handleClose = async () => {
      setDialogState((prev) => {
        return {
          ...prev,
          visible: false,
          data: null,
        };
      });

      if (onClose) {
        await onClose();
      }
    };

    const deleteData = async (item: SafePasswordDto) => {
      await passService.delete(item);
      await onDelete(item);

      setDialogState((prev) => {
        return {
          ...prev,
          visible: false,
        };
      });
    };

    const addOrUpdateRecord = async (input: SafePasswordDto) => {
      try {
        if (data && data.id) {
          input.id = data.id;
        }
        const savedData = await passService.save(input, masterPw);

        setDialogState((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
        await onSave(savedData);
      } catch (e) {
        console.error("Error adding document: ", e);
        await onSave(null);
      }
    };

    useImperativeHandle(ref, () => ({
      showDialog(item: SafePasswordDto | null) {
        setDialogState((prev) => {
          return {
            ...prev,
            visible: true,
            data: item,
          };
        });
      },

      hideDialog() {
        setDialogState((prev) => {
          return {
            ...prev,
            visible: false,
            data: null,
          };
        });
      },
    }));

    const schema = yup.object({
      account: yup.string().max(255).required("*").min(2),
      username: yup.string().required("*").max(255),
      password: yup.string().required("*").max(255),
      url: yup.string().max(1000).url("Wrong format!").notRequired(),
    });

    // https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
    // https://react-bootstrap.github.io/forms/validation/
    // https://org/docs/tutorial

    return (
      <Modal
        show={visible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>

        <Formik<SafePasswordDto>
          initialValues={data ?? ({ account: "" } as SafePasswordDto)}
          validateOnChange
          onSubmit={(values: SafePasswordDto | {}, actions) => {
            addOrUpdateRecord(values as SafePasswordDto);
            actions.resetForm();
            actions.setStatus(false);
          }}
          validationSchema={schema}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId={modelProp("account")}>
                  <Form.Label>{strings.pass.account}</Form.Label>
                  <Form.Control
                    name={modelProp("account")}
                    onBlur={handleBlur}
                    placeholder={strings.pass.account}
                    onChange={handleChange}
                    value={values.account}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.account}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId={modelProp("username")}>
                  <Form.Label>{strings.pass.username}</Form.Label>
                  <Form.Control
                    name={modelProp("username")}
                    onBlur={handleBlur}
                    placeholder={strings.pass.username}
                    onChange={handleChange}
                    value={values.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId={modelProp("password")}>
                  <Form.Label>{strings.pass.password}</Form.Label>

                  <PasswordField
                    editable={true}
                    className="form-control"
                    onBlur={handleBlur}
                    name={modelProp("password")}
                    onChange={handleChange}
                    placeholder={strings.pass.password}
                    value={values.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId={modelProp("url")}>
                  <Form.Label>{strings.pass.url}</Form.Label>
                  <Form.Control
                    name={modelProp("url")}
                    placeholder={strings.pass.url}
                    onChange={handleChange}
                    value={values.url ?? ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.url}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId={modelProp("details")}>
                  <Form.Label>{strings.pass.details}</Form.Label>
                  <Form.Control
                    name={modelProp("details")}
                    as="textarea"
                    rows={3}
                    placeholder={strings.pass.details}
                    onChange={handleChange}
                    value={values.details ?? ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.details}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {strings.shared.cancel}
                </Button>
                {data && (
                  <Button variant="secondary" onClick={() => deleteData(data)}>
                    {strings.shared.delete}
                  </Button>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}>
                  {data ? strings.shared.save : strings.shared.add}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
);

export default PassDialog;
