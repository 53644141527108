import CryptoJS from 'crypto-js';
import xmlescape from "xml-escape"

export const getBrowserLocales = (options: { languageCodeOnly: boolean } = { languageCodeOnly: false }) => {
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? (trimmedLocale.split(/-|_/)[0])?.toLocaleLowerCase()
            : trimmedLocale?.toLocaleLowerCase();
    });
}

export const trimEndTrailingSlashes = (input?: string): string | undefined => {
    return input?.replace(/\/$/, "");
}

export const generateMOTP = (secret: string, pin: string, length: number = 6): string => {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const counter = Math.floor(timestamp / 10);

    const data = counter.toString() + secret + pin;
    const hash = CryptoJS.MD5(data).toString();

    const mOTP = hash.substr(0, length);

    return mOTP;
}



export const escapeXml = (unsafe: string | null | undefined): string => {
    if (!unsafe) {
        return '';
    }

    return xmlescape(unsafe);

    //return unsafe.replace(/[<>&'"]/g, (c) => {
    //    switch (c) {
    //        case '<': return '&lt;';
    //        case '>': return '&gt;';
    //        case '&': return '&amp;';
    //        case '\'': return '&apos;';
    //        case '"': return '&quot;';
    //        default: return c;
    //    }
    //});
}

export const escapeCsvValue = (value) => {
    if (value.includes(',') || value.includes('\n') || value.includes('"')) {
        return `"${value.replace(/"/g, '""')}"`; // Escape double-quotes and wrap in double-quotes
    }
    return value;
}