import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useLocalize } from '../shared/hooks/localize';
import { Col, Row } from 'react-bootstrap';
import { generateMOTP } from '../common/utils';
import { mOTPService } from '../services/MOTPService';
import { FileEarmarkPlus, PencilSquare } from 'react-bootstrap-icons';

import * as yup from "yup";
import { Formik } from 'formik';
import { MOTPSecret } from '../shared/models';

const modelProp = (x: keyof MOTPSecret) => x.toString();

function generateSecret(length = 16) {
    const array = new Uint8Array(length);
    crypto.getRandomValues(array);
    return Array.from(array)
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
}

function generatePassword(
    alphanumericCount = 4,
    uppercaseCount = 4,
    lowercaseCount = 4,
    specialCount = 4
  ) {
    const alphanumericChars = '0123456789';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';
  
    const getRandomChars = (chars, count) => {
      let result = '';
      for (let i = 0; i < count; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        result += chars[randomIndex];
      }
      return result;
    };
  
    const password =
      getRandomChars(alphanumericChars, alphanumericCount) +
      getRandomChars(uppercaseChars, uppercaseCount) +
      getRandomChars(lowercaseChars, lowercaseCount) +
      getRandomChars(specialChars, specialCount);
  
    return password.split('').sort(() => Math.random() - 0.5).join('');
  }

const OtpPage = () => {

    const { strings } = useLocalize();
    const [mOTP, setMOTP] = useState('');
    const [pin, setPin] = useState('');
    const [secret, setSecret] = useState<MOTPSecret | null>(null)
    const [editSecret, setEditSecret] = useState<MOTPSecret | null>(null)
    const [secrets, setSecrets] = useState<MOTPSecret[]>([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //const secrets = ["3457b921dc30bde48613a32ab03ae86d", "test2"];

    const handleClick = async () => {
        if (secret) {
            let token = generateMOTP(secret.secret, pin);
            setMOTP(token);
            await window.navigator.clipboard.writeText(token).then(() => {
                setTimeout(() => setMOTP(""), 5000);
            })
        }
    };

    const handleSecretChange = (e) => {
        let selectedId = e.target.value;
        let selectedSecret = secrets.find((o) => o.id === selectedId);
        setSecret(selectedSecret ?? null);
    };

    const handlePinChange = (e) => {
        setPin(e.target.value);
    };

    const loadSecrets = async () => {
        const fetchedData = await mOTPService.getSecrets();
        setSecrets(fetchedData);

        if (fetchedData.length == 0) {
            setSecret(null)
        } else {
            if (!secret) {
                setSecret(fetchedData[0])
            }
        }

    }

    useEffect(() => {
        loadSecrets();
    }, []);


    const handleSaveChanges = async (data: MOTPSecret) => {
        if (data?.id) {
            await mOTPService.updateSecret(data.id, data.name, data.secret);
        }
        else {
            await mOTPService.addSecret(data.name, data.secret);
        };

        await loadSecrets();
        setShow(false);
        setEditSecret(null);
    };

    const handleDelete = async () => {
        if (editSecret?.id) {
            await mOTPService.deleteSecret(editSecret.id);
            await loadSecrets();
            setShow(false);
            setEditSecret(null);
        }
    };

    const handleEdit = (data: MOTPSecret) => {
        setEditSecret(data)
        setShow(true);
    }

    const schema = yup.object({
        name: yup.string().max(255).required("*").min(2),
        secret: yup.string().required("*").max(255)
    });

    return (
        <div>
            <Row>
                <Col>
                    <h1>mOTP <FileEarmarkPlus title='Add new secret' onClick={() => {
                        handleEdit({ secret: generateSecret() } as MOTPSecret)
                    }} /></h1>
                </Col>
            </Row>
            <Row className="">
                <Col md="auto">
                    <Form.Control
                        as="select"
                        value={secret?.id}
                        onChange={handleSecretChange}
                        title={secret?.secret}
                        placeholder="Secret"

                        style={{ minWidth: "100px", maxWidth: '275px' }}
                    >
                        {secrets.map(secret => (
                            <option key={secret.id} value={secret.id} >
                                {secret.name}
                            </option>
                        ))}
                    </Form.Control>

                    {mOTP && (
                        <span className='m-1 p-1'> {mOTP}</span>
                    )}

                </Col>
                <Col md="auto">
                    <Form.Control
                        type="password"
                        placeholder="PIN"
                        value={pin}
                        maxLength={6}
                        onChange={handlePinChange}
                        style={{ maxWidth: '75px' }}
                    />
                </Col>


                {secret?.id && (
                    <Col md="auto">
                        <Button disabled={!secret} variant="primary" onClick={() => handleEdit(secret)}>
                            <PencilSquare title='Edit' /> Edit
                        </Button>
                    </Col>
                )}

                <Col md="auto">
                    <Button disabled={!(secret?.id)} variant="primary" onClick={handleClick}>
                        Generate
                    </Button>
                </Col>
            </Row>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>mOTP Secret</Modal.Title>
                </Modal.Header>


                <Formik<MOTPSecret>
                    initialValues={editSecret ?? ({} as MOTPSecret)}
                    validateOnChange
                    onSubmit={(values: MOTPSecret | {}, actions) => {
                        handleSaveChanges(values as MOTPSecret);
                        actions.resetForm();
                        actions.setStatus(false);
                    }}
                    validationSchema={schema}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        isValid,
                        isSubmitting,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Group className="mb-3" controlId={modelProp("name")}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        name={modelProp("name")}
                                        onBlur={handleBlur}
                                        placeholder={"Name"}
                                        onChange={handleChange}
                                        value={values.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId={modelProp("secret")}>
                                    <Form.Label>Secret</Form.Label>
                                    <Form.Control
                                        name={modelProp("secret")}
                                        onBlur={handleBlur}
                                        placeholder={"Secret"}
                                        onChange={handleChange}
                                        value={values.secret}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.secret}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {strings.shared.cancel}
                                </Button>
                                {editSecret?.id && (
                                    <Button variant="secondary" onClick={handleDelete}>
                                        {strings.shared.delete}
                                    </Button>
                                )}

                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}>
                                    {editSecret?.id ? strings.shared.save : strings.shared.add}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>

            </Modal>

        </div>
    );
};

export default OtpPage;
