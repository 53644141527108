import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { saveAs } from "file-saver";
import { DataResult, SafePasswordDto } from "../shared/models";
import { passService } from "../services/PassService";
import { useLocalize } from "../shared/hooks/localize";
import { CloudDownload, CloudUpload, Plus } from "react-bootstrap-icons";
import PassDialog from "./components/PassDialog";
import notifyHelper from "../shared/notifyHelper";
import PassCard from "./components/PassCard";
import CustomMenuToggle from "../shared/components/CustomMenuToggle";
import { appConfig, appInstance } from "../shared/configs";
import { Loader } from "../shared/components/Laoder";
import CustomPagination from "../shared/components/CustomPagination";
import MasterPassDialog from "./components/MasterPassDialog";
import { env } from "process";

const timeStr = () => {
  let d = new Date();

  return [
    d.getFullYear(),
    d.getMonth() + 1,
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
  ].join("");
};

const pagerSize = 20;

interface PageState {
  loading: boolean;
  updatingMasterPass: boolean;
  passwordsLoaded: boolean;
  data: SafePasswordDto[];
  searchKey: string | null;
  selectedPage: number;
  totalCount: number;
  masterPw: string;
}

const PassPage = (props: any) => {
  const { strings } = useLocalize();

  const dialogRef = useRef();
  const importRef = useRef<HTMLInputElement>(null);
  const searchBox = useRef<HTMLInputElement>(null);

  const [pageState, setPageState] = useState<PageState>({
    loading: true,
    data: [],
    passwordsLoaded: false,
    updatingMasterPass: false,
    searchKey: null,
    selectedPage: 1,
    totalCount: -1,
    masterPw: "",
  });

  const [searchInput, setSearchInput] = useState("");

  const {
    loading,
    searchKey,
    selectedPage,
    totalCount,
    data,
    masterPw,
    updatingMasterPass,
  } = pageState;

  const handleAddOrEdit = (item?: SafePasswordDto | null) => {
    const dialog = dialogRef.current as any;
    dialog?.showDialog(item);
  };

  const loadData = useCallback(async (): Promise<boolean> => {
    if (!masterPw) {
      return false;
    }

    setPageState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    let result = false;
    let dtoData: DataResult<SafePasswordDto> =
      {} as DataResult<SafePasswordDto>;
    try {
      dtoData = await passService.getUserPasswords(
        searchKey ?? "",
        masterPw,
        true,
        false,
        { pagerSize: pagerSize, pageNumber: selectedPage }
      );
      result = true;
    } catch { }

    setPageState((prev) => {
      return {
        ...prev,
        loading: false,
        data: result ? dtoData.data : [],
        totalCount: result ? dtoData.totalCount : 0,
      };
    });

    searchBox.current?.focus();

    return result;
  }, [masterPw, searchKey, selectedPage]);

  const deleteData = async (item: SafePasswordDto) => {
    var delResult = await passService.delete(item);

    if (delResult) {
      await onDelete(item);
    }
  };

  const onSave = async (item: SafePasswordDto | null) => {
    if (item) {
      await loadData();
      notifyHelper.info("Added!!");
    }
  };

  const onDelete = async (item: SafePasswordDto | null) => {
    if (item) {
      await loadData();
      notifyHelper.info("Deleted!!");
    }
  };

  const loadInitialData = async () => {
    setPageState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    await passService.getSafepasswords(true);

    setPageState((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!searchInput || searchInput.length === 0 || searchInput.length > 2) {
        setPageState((prev) => {
          return {
            ...prev,
            searchKey: searchInput,
          };
        });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchInput]);

  useEffect(() => {
    loadInitialData();
    return () => { };
  }, []);

  useEffect(() => {
    loadData();
    return () => { };
  }, [loadData, masterPw]);

  const onSearchKeyDown = async (e: any) => {
    if (e.keyCode !== 13) {
      return;
    }

    const newSearchKey = e.target.value;

    setPageState((prev) => {
      return {
        ...prev,
        searchKey: newSearchKey,
      };
    });

    searchBox.current?.focus();
  };

  const onUpload = async (e: any) => {
    try {
      e.preventDefault();
      setPageState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      if (e.target.files != null && e.target.files.length > 0) {
        let count = await passService.import(
          e.target.files[0],
          pageState.masterPw
        );
        if (count > 0) {
          notifyHelper.info("Sucess:" + count);

          setPageState((prev) => {
            return {
              ...prev,
              totalCount: -1,
              selectedPage: 1,
            };
          });

          await loadData();
        } else {
          notifyHelper.info(strings.pass.noUpdateOnImport);
        }
      }
    } catch (ex) {
      notifyHelper.warn(strings.shared.technicalError);
    } finally {
      e.target.value = null;

      setPageState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onKeepassDownload = async (e: any) => {
    try {
      e.preventDefault();
      setPageState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      let resp = await passService.downloadKeepasXml(pageState.masterPw);

      if (!resp || resp.size === 0) {
        notifyHelper.warn(strings.shared.technicalError);
      } else {
        let fileName = `${appInstance.auth.currentUser?.email?.replace(
          /\W/g,
          "_"
        )}_pass_${timeStr()}_keepass.xml`;
        saveAs(resp, fileName, { autoBom: true });
      }
    } catch (err) {
      if (appConfig.environment === "development") {
        console.error(err);
      }
      notifyHelper.warn(strings.shared.technicalError);
    } finally {
      setPageState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onCsvDownload = async (e: any) => {
    try {
      e.preventDefault();
      setPageState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      let resp = await passService.downloadCsv(pageState.masterPw);

      if (!resp || resp.size === 0) {
        notifyHelper.warn(strings.shared.technicalError);
      } else {
        let fileName = `${appInstance.auth.currentUser?.email?.replace(
          /\W/g,
          "_"
        )}_pass_${timeStr()}_generic.csv`;
        saveAs(resp, fileName, { autoBom: true });
      }
    } catch (err) {
      if (appConfig.environment === "development") {
        console.error(err);
      }
      notifyHelper.warn(strings.shared.technicalError);
    } finally {
      setPageState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onDownload = async (e: any) => {
    try {
      e.preventDefault();
      setPageState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      let resp = await passService.download(pageState.masterPw);

      if (!resp || resp.size == 0) {
        notifyHelper.warn(strings.shared.technicalError);
      } else {
        let fileName = `${appInstance.auth.currentUser?.email?.replace(
          /\W/g,
          "_"
        )}_pass_${timeStr()}.json`;
        saveAs(resp, fileName, { autoBom: true });
      }
    } catch (err) {
      notifyHelper.warn(strings.shared.technicalError);
    } finally {
      setPageState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const onPageChange = async (page: number) => {
    setPageState((prev) => {
      return {
        ...prev,
        selectedPage: page,
      };
    });
  };

  const onMasterPassSet = async (
    value: string,
    isUpdate: boolean
  ): Promise<boolean> => {
    let isValid = isUpdate
      ? await passService.changeMasterPass(masterPw, value)
      : await passService.checkMasterPass(value);

    if (isValid) {
      setPageState((prev) => {
        return {
          ...prev,
          masterPw: value,
          updatingMasterPass: false,
        };
      });
    } else {
      notifyHelper.error(strings.pass.wrongMasterpass);
    }

    return isValid;
  };

  const onMasterPwChange = (cancel: boolean = false) => {
    setPageState((prev) => {
      return {
        ...prev,
        updatingMasterPass: !cancel,
      };
    });
  };

  if (loading) {
    return <Loader visible />;
  }

  return (
    <React.Fragment>
      <MasterPassDialog
        isUpdate={updatingMasterPass}
        visible={!masterPw || updatingMasterPass}
        onSubmit={onMasterPassSet}
        onCancel={() => onMasterPwChange(true)}
      />

      {!!masterPw && !updatingMasterPass && (
        <React.Fragment>
          <input
            ref={importRef}
            type="file"
            name="file"
            onChange={onUpload}
            style={{ display: "none" }}
          />
          <PassDialog
            ref={dialogRef}
            onSave={onSave}
            onDelete={onDelete}
            masterPw={pageState.masterPw}
          />

          <div className="d-flex flex-column p-1 m-1">
            <div className="d-flex flex-row align-items-center align-content-start">
              <div className="flex-grow-1 me-1">
                <Form.Control
                  type="input"
                  name="search"
                  className="rounded-3"
                  ref={searchBox}
                  placeholder="Search..."
                  defaultValue={searchKey ?? ""}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={onSearchKeyDown}
                />
              </div>
              <div className="">
                <Button
                  variant="primary"
                  className="p-0 m-0"
                  onClick={() => handleAddOrEdit(null)}>
                  <Plus className="fs-1" title="Add" />
                </Button>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    as={CustomMenuToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        onDownload(e);
                      }}>
                      <CloudDownload className="fs-5 me-1"></CloudDownload>
                      <span>{strings.shared.export}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        onKeepassDownload(e);
                      }}>
                      <CloudDownload className="fs-5 me-1"></CloudDownload>
                      <span>{strings.shared.keepassExport}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        onCsvDownload(e);
                      }}>
                      <CloudDownload className="fs-5 me-1"></CloudDownload>
                      <span>{strings.shared.csvExport}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        importRef.current?.click();
                      }}>
                      <CloudUpload className="fs-5 me-1"></CloudUpload>
                      <span>{strings.shared.import}</span>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => onMasterPwChange()}>
                      <CloudUpload className="fs-5 me-1"></CloudUpload>
                      <span>{strings.pass.changeMasterpass}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="pt-2">
              <CustomPagination
                onChange={onPageChange}
                totalCount={totalCount}
                pagerSize={pagerSize}
                selectedPage={selectedPage}
              />
            </div>
            <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-stretch">
              {data.map((x) => {
                return (
                  <PassCard
                    key={"rr" + x.id}
                    item={x}
                    onDelete={() => deleteData(x)}
                    onEdit={() => handleAddOrEdit(x)}
                  />
                );
              })}
            </div>
            <div className="pt-2">
              <CustomPagination
                onChange={onPageChange}
                totalCount={totalCount}
                pagerSize={pagerSize}
                selectedPage={selectedPage}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PassPage;
