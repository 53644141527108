import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useAppAuth } from "../shared/hooks/auth";
import { getReturnUrl } from "../shared/configs";
import { useNavigate } from "react-router-dom";
import { Loader } from "../shared/components/Laoder";
import { useLocalize } from "../shared/hooks/localize";
import { Google, ArrowRightCircleFill } from "react-bootstrap-icons";

// https://getbootstrap.com/docs/5.1/utilities/text/
// https://react-bootstrap.netlify.app/components/buttons/

export const LoginPage = () => {
  const navigate = useNavigate();
  const {
    isAuthenticating,
    isAuthenticated,
    isInitialized,
    login,
    loginByGoogle,
  } = useAppAuth();
  const { localizePath, strings } = useLocalize();

  const [validated, setValidated] = useState<boolean>(false);

  // Hook to store the result of the validation
  const [loginError, setLoginError] = useState<boolean>(false);

  const signInWithGoogle = () => {
    loginByGoogle(getReturnUrl());
  };

  const signInWithPassword = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();

    setValidated(true);

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData.entries()) as any;

    const loginResult = await login(
      formDataObj.email,
      formDataObj.password,
      getReturnUrl()
    );

    setLoginError(!loginResult); 
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(getReturnUrl() ?? localizePath("/"));
    }
  }, [isAuthenticated, localizePath, navigate]);

  if (!isInitialized) {
    return <Loader visible />;
  }
  return (
    <div className="mx-auto my-5" style={{ maxWidth: "25rem", width: "20rem" }}>
      <Card>
        <Card.Header className="fw-bold">
          {strings.account.loginTitle}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={signInWithPassword} noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> {strings.account.email}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                placeholder={strings.account.email}
              />
              <Form.Control.Feedback type="invalid">
                {strings.account.emailRequired}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{strings.account.password}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                placeholder={strings.account.password}
              />
              <Form.Control.Feedback type="invalid">
                {strings.account.passwordRequired}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              size="sm"
              className="text-start w-100"
              type="submit"
              disabled={isAuthenticating}>
              <ArrowRightCircleFill /> {strings.account.signin}
            </Button>

            <Form.Control.Feedback
              type="invalid"
              style={{ display: loginError ? "block" : "none" }}>
              {strings.account.wrongPassOrUsername}
            </Form.Control.Feedback>
          </Form>
        </Card.Body>
        <Card.Footer className="d-grid gap-2">
          <Button
            type="button"
            size="sm"
            className="text-start"
            variant="secondary"
            onClick={signInWithGoogle}
            disabled={isAuthenticating}>
            <Google /> {strings.account.signInViaGoogle}
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default LoginPage;
