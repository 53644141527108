import {
  Linkedin,
  Envelope,
  House,
  Key,
  Icon,
  ShieldCheck,
  InfoCircle,
  Bandaid
} from "react-bootstrap-icons";
import React from "react";
import LoginPage from "../pages/Login";
import HomePage from "../pages/Home";
import PassPage from "../pages/Pass";
import { ApplicationPaths } from "../shared/configs";
import NotReady from "../pages/NotReady";
import OtpPage from "../pages/Otp";

export interface NavigationItem {
  key: string;
  title?: string;
  link?: string;
  component?: React.ReactElement;
  icon?: (props: any) => JSX.Element;
  path?: string;
  requireAuth?: boolean;
  isNavItem?: boolean;
  alwaysShow?: boolean;
}

export const navigationItems: NavigationItem[] = [
  {
    key: "home",
    title: "Home",
    path: "/",
    icon: (props: any) => <House {...props} />,
    component: <HomePage />,
    requireAuth: true,
    isNavItem: true,
    alwaysShow: true,
  },

  {
    key: "linkedin",
    title: "Linkedin",
    link: process.env.REACT_APP_CONTACT_LINKEDIN,
    icon: (props: any) => <Linkedin {...props} />,
  },

  {
    key: "email",
    title: "E-Mail",
    link: process.env.REACT_APP_CONTACT_EMAIL,
    icon: (props: any) => <Envelope {...props} />,
  },

  {
    requireAuth: true,
    key: "passwords",
    path: "/list",
    title: "Passwords",
    icon: (props: any) => <Key {...props} />,
    component: <PassPage />,
    isNavItem: true,
  }, {
    requireAuth: true,
    key: "otp",
    path: "/otp",
    title: "OTP",
    icon: (props: any) => <Bandaid {...props} />,
    component: <OtpPage />,
    isNavItem: true,
  },
  {
    key: "imprint",
    path: "/imprint",
    component: <NotReady />,
    icon: (props: any) => <InfoCircle {...props} />,
    isNavItem: true,
  },
  {
    key: "privacy",
    path: "/privacy",
    component: <NotReady />,
    icon: (props: any) => <ShieldCheck {...props} />,
    isNavItem: true,
  },

  {
    key: "login",
    path: ApplicationPaths.Login,
    component: <LoginPage />,
  },
];
