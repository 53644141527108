import React from "react";
import { Link, useLocation } from "react-router-dom";
import { navigationItems } from "../../common/navigationConfig"; 
import { useAppAuth } from "../../shared/hooks/auth";
import { useLocalize } from "../../shared/hooks/localize";
export const NavMenu = (props: { open: boolean }) => {
  const { isAuthenticated } = useAppAuth();
  const { localizePath, strings } = useLocalize();
  const location = useLocation();

  const menuStr = strings.menu as any;

  return (
    <ul
      className="nav flex-column align-items-start  align-content-start justify-content-start pb-5"
      id="menu">
      {navigationItems
        .filter(
          (x) =>
            x.path &&
            x.isNavItem &&
            (x.alwaysShow || !x.requireAuth || isAuthenticated)
        )
        .map((item) => {
          const isNavItemActive =
            localizePath(item.path ?? "") === location.pathname;

          return (
            <li
              className={
                "nav-item" + (isNavItemActive ? " active-nav-item" : "")
              }
              key={item.key}>
              <Link
                key={item.key}
                to={localizePath(item.path ?? "/")}
                className="nav-link px-0">
                {item.icon &&
                  item.icon({
                    className: "nav-menu-icon mx-1",
                    title: item.title,
                  })}
                {props.open && (
                  <span className="ms-1">
                    {menuStr[item.key] ?? item.title}
                  </span>
                )}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};
