import { useFormik } from "formik";
import * as yup from "yup";
import React, { useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useLocalize } from "../../shared/hooks/localize";
import { PasswordField } from "../../shared/components/PasswordField";
import notifyHelper from "../../shared/notifyHelper";

interface PassDto {
  value: string;
}

const modelProp = (x: keyof PassDto) => x.toString();

const MasterPassDialog = (props: {
  onSubmit: (value: string, isUpdate: boolean) => Promise<boolean>;
  onCancel: () => void;
  isUpdate: boolean;
  visible: boolean;
}) => {
  const { strings } = useLocalize();

  const { onSubmit, isUpdate, visible } = props;

  const addOrUpdateRecord = async (data: PassDto) => {
    try {
      if (data && data.value) {
        await onSubmit(data.value, isUpdate);
      }
    } catch (e) {
      console.error(e);
      notifyHelper.error(strings.shared.technicalError);
    }
  };

  const handleClose = () => {
    props.onCancel();
  };

  const validationSchema = yup.object({
    [modelProp("value")]: yup.string().required("*").min(4).max(25),
  });

  const formik = useFormik<PassDto>({
    validationSchema: validationSchema,
    validateOnChange: true,
    initialValues: {} as PassDto,
    onSubmit: (values: PassDto | {}, actions) => {
      addOrUpdateRecord(values as PassDto);
      actions.resetForm();
      actions.setStatus(false);
    },
  });

  if (!visible) {
    return <React.Fragment />;
  }

  return (
    <div
      className="border border-primary mx-auto my-2 p-2"
      style={{ minWidth: "250px", maxWidth: "350px", width: "25%" }}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId={modelProp("value")}>
          <Form.Label className="fw-bolder">
            {strings.pass.masterPassword}
          </Form.Label>

          <PasswordField
            editable={true}
            className="form-control"
            id={modelProp("value")}
            name={modelProp("value")}
            placeholder={strings.pass.password}
            defaultValue={""}
            onChange={formik.handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.value}
          </Form.Control.Feedback>
        </Form.Group>

        <div className=" d-flex flex-row justify-content-end ">
          {isUpdate && (
            <Button variant="secondary" className="me-2" onClick={handleClose}>
              {strings.shared.cancel}
            </Button>
          )}

          <Button variant="primary" type="submit" disabled={!formik.isValid}>
            {strings.shared.enter}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MasterPassDialog;
