import { appConfig, appInstance } from "../shared/configs";
import {
  DataResult,
  PagerQuery,
  SafePasswordDto,
  SafePasswordImportDto,
  SafePasswords,
} from "../shared/models";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  QueryConstraint,
  Timestamp,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import ld, { each, groupBy, isEqual, keys, orderBy, slice } from "lodash";
import nameof from "../shared/nameof";
import xmlFormat from 'xml-formatter';
import { escapeCsvValue } from "../common/utils";

const collName = "password_sets";

const getCollection = () => collection(appInstance.db, collName);

const getDocRef = (id: string) => doc(appInstance.db, collName, id);

const objToMap = (obj: any) => {
  const result = {} as any;

  Object.keys(obj).forEach((x) => {
    result[x] = obj[x];
  });

  return result;
};

const filterData = (searchKey: string | null, list: SafePasswordDto[]) => {
  let fdata = list;

  if (searchKey && fdata?.length > 0) {
    let s = searchKey.toLowerCase();
    fdata = list.filter(
      (x) =>
        x.account?.toLowerCase().includes(s) ||
        x.username?.toLowerCase().includes(s) ||
        x.url?.toLowerCase().includes(s) ||
        x.details?.toLowerCase().includes(s)
    );
  }
  return fdata;
};

const toDtoArray = (
  res: SafePasswords,
  masterKey: string,
  decrypt: boolean = true
): SafePasswordDto[] => {
  const result = new Array<SafePasswordDto>();

  if (res?.data?.length > 0) {
    let data = res.data.map((x) => Object.assign({}, x));
    //  JSON.parse(JSON.stringify(res.data),) as SafePasswordDto[];

    data.forEach((r) => {
      if (decrypt) {
        r.password = r.salt
          ? appInstance.decrypt(r.password, masterKey, r.salt)
          : r.password;
      }
      result.push(r);
    });
  }

  return result;
};

export class PassService {
  /*
    public async getAll(
      searchText: string | null,
      decrypt: boolean = true,
      pagerQuery?: PagerQuery,
      requireTotal?: boolean
    ): Promise<DataResult<SafePasswordDto>> {
  
      const result = {} as DataResult<SafePasswordDto>;
      result.totalCount = 0;
  
      var queryConstraints = new Array<QueryConstraint>();
  
      if (searchText) {
        queryConstraints.push(where(nameof<SafePasswordDto>(x => x.account), "==", searchText))
        queryConstraints.push(where(nameof<SafePasswordDto>(x => x.details), "==", searchText))
        queryConstraints.push(where(nameof<SafePasswordDto>(x => x.username), "==", searchText))
      }
  
      queryConstraints.push(where(nameof<SafePasswordDto>(x => x.owner), "==", appInstance.auth.currentUser?.email))
  
      if (requireTotal) {
        const allDocumentSnapshots = await getDocs(query(getCollection(), ...queryConstraints));
        result.totalCount = allDocumentSnapshots.size;
      }
  
      queryConstraints.push(orderBy(nameof<SafePasswordDto>(x => x.updatedDate), "desc"))
  
  
      if (pagerQuery && pagerQuery.pageNumber > 0 && pagerQuery.pagerSize > 1) {
  
        // queryConstraints.push(limit(pagerQuery.pageNumber * pagerQuery.pagerSize));
  
        if (pagerQuery.pageNumber > 1) {
          const documentSnapshots = await getDocs(query(getCollection(), ...queryConstraints, limit((pagerQuery.pageNumber - 1) * pagerQuery.pagerSize)));
  
          // Get the last visible document
          const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  
          if (lastVisible) {
            queryConstraints.push(startAfter(lastVisible));
          }
        }
  
        queryConstraints.push(limit(pagerQuery.pagerSize));
  
      }
  
      const docsQuery = await getDocs(query(getCollection(), ...queryConstraints));
  
      result.data = docsQuery.docs.map(x => toModel(x, decrypt));
  
  
      return result;
    }
  
    */

  private cache?: SafePasswords;

  public async getSafepasswords(force: boolean = false) {
    if (!force && this.cache) {
      if (this.cache.owner === appInstance.auth.currentUser?.email) {
        return this.cache;
      }
    }

    var queryConstraints = new Array<QueryConstraint>();

    queryConstraints.push(
      where(
        nameof<SafePasswords>((x) => x.owner),
        "==",
        appInstance.auth.currentUser?.email
      )
    );

    //  queryConstraints.push(orderBy(nameof<SafePasswordDto>(x => x.updatedDate), "desc"))

    const docsQuery = await getDocs(
      query(getCollection(), ...queryConstraints)
    );

    this.cache = (docsQuery.docs[0]?.data() ?? {
      isTransient: true,
    }) as SafePasswords;

    this.cache.data?.forEach((x: any) => {
      if (x.updatedDate && x.updatedDate.seconds && x.updatedDate.nanoseconds) {
        x.updatedDate = new Timestamp(
          x.updatedDate.seconds,
          x.updatedDate.nanoseconds
        ).toDate();
      } else {
        x.updatedDate = new Date(x.updatedDate);
      }
    });

    if (!this.cache.id) {
      this.cache.id = uuidv4();
      this.cache.isTransient = true;
    }

    if (!this.cache.owner) {
      this.cache.owner = appInstance.auth.currentUser?.email ?? "";
    }

    if (!this.cache.data) {
      this.cache.data = [];
    }

    return this.cache;
  }

  public async getUserPasswords(
    searchKey: string | null = "",
    masterKey: string,
    decrypt: boolean = true,
    force: boolean = false,
    pagerQuery?: PagerQuery
  ): Promise<DataResult<SafePasswordDto>> {
    const result = {} as DataResult<SafePasswordDto>;

    let res = await this.getSafepasswords(force);
    let data = orderBy(
      filterData(searchKey, toDtoArray(res, masterKey, decrypt)),
      (x) => x.updatedDate,
      "desc"
    );

    result.totalCount = data.length;

    if (pagerQuery && pagerQuery.pageNumber > 0 && pagerQuery.pagerSize > 1) {
      const skip = pagerQuery.pagerSize * (pagerQuery.pageNumber - 1);

      data = slice(data, skip, skip + pagerQuery.pagerSize);
    }

    result.data = data;

    return result;
  }

  public async checkMasterPass(masterPass: string): Promise<boolean> {
    let res = await this.getSafepasswords(false);

    if (res?.data?.length > 0) {
      try {
        const testData = res.data[0];
        const decryptRes = appInstance.decrypt(
          testData.password,
          masterPass,
          testData.salt
        );
        return !!decryptRes;
      } catch (err) {

        if (appConfig.environment === "development") {
          console.error(err);
        }

        return false;
      }
    }
    return true;
  }

  public async changeMasterPass(
    masterPass: string,
    newMasterPass: string
  ): Promise<boolean> {
    let res = await this.getSafepasswords(true);

    if (res?.data?.length > 0) {
      try {
        const currentData = toDtoArray(res, masterPass, true);

        res.data = currentData.map((x) => {
          x.salt = appInstance.generateSalt();
          x.password = appInstance.encrypt(x.password, newMasterPass, x.salt);
          return x;
        });

        await this.saveOrUpdate(res);
      } catch (err) {

        if (appConfig.environment === "development") {
          console.error(err);
        }

        return false;
      }
    }
    return true;
  }

  private async saveOrUpdate(passGroup: SafePasswords) {
    const isTransient = passGroup.isTransient;
    delete passGroup.isTransient;

    const docRef = getDocRef(passGroup.id);

    if (isTransient) {
      await setDoc(docRef, passGroup);
    }
    {
      const { id, ...rest } = passGroup;

      await updateDoc(docRef, objToMap(rest));
    }

    this.cache = passGroup;
  }

  public async save(
    data: SafePasswordDto,
    masterKey: string
  ): Promise<SafePasswordDto | null> {
    try {
      data.updatedDate = new Date();

      var passGroup = await this.getSafepasswords(false);

      if (data.password) {
        data.salt = appInstance.generateSalt();
        data.password = appInstance.encrypt(
          data.password,
          masterKey,
          data.salt
        );
      }

      if (!data.id) {
        data.id = uuidv4();
        data.createdDate = data.updatedDate;
      } else {
        let persistedData = passGroup.data.filter((x) => x.id === data.id)[0];

        if (persistedData) {
          data = Object.assign({}, persistedData, data);
        }
      }

      passGroup.data = passGroup.data.filter((x) => x.id !== data.id) ?? [];

      passGroup.data.push(data);

      await this.saveOrUpdate(passGroup);

      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async delete(data: SafePasswordDto): Promise<boolean> {
    var passGroup = await this.getSafepasswords(false);

    passGroup.data = passGroup.data.filter((x) => x.id !== data.id);

    await this.saveOrUpdate(passGroup);

    return true;
  }

  public async download(masterKey: string): Promise<Blob> {
    //return fetch(`${appConfig.apiUrl}/sp/export`, {
    //  method: "POST",
    //})

    const result = await this.getUserPasswords("", masterKey, false, true);
    var exportData =
      result?.data?.map(
        (x) => Object.assign({}, x, { notPlain: true }) as SafePasswordImportDto
      ) ?? [];

    const resultBlob = new Blob([JSON.stringify(exportData)], {
      type: "application/json",
    });
    return Promise.resolve(resultBlob);
  }

  public async downloadKeepasXml(masterKey: string): Promise<Blob> {

    const result = await this.getUserPasswords("", masterKey, true, true);

    const keepassXml = this.generateKeePassXML(result.data);

    const resultBlob = new Blob([keepassXml], {
      type: "application/yml",
    });
    return Promise.resolve(resultBlob);
  }

  public async downloadCsv(masterKey: string): Promise<Blob> {

    const result = await this.getUserPasswords("", masterKey, true, true);


    const mapping = [
      { id: nameof<SafePasswordDto>(_ => _.account), title: 'Title' },
      { id: nameof<SafePasswordDto>(_ => _.url), title: 'URL' },
      { id: nameof<SafePasswordDto>(_ => _.username), title: 'Username' },
      { id: nameof<SafePasswordDto>(_ => _.password), title: 'Password' },
      { id: nameof<SafePasswordDto>(_ => _.details), title: 'Notes' },
      { id: "OTPAuth", title: 'OTPAuth' }];


    const headers = mapping.map(m => escapeCsvValue(m.title));

    const csvRows = [
      headers.join(','), // Add the header row
      ...result.data.map(row =>
        mapping.map(m => escapeCsvValue(String(row[m.id] || ''))).join(',')
      )
    ];

    const csvString = csvRows.join('\n');

    const resultBlob = new Blob([csvString], {
      type: "text/csv",
    });
    return Promise.resolve(resultBlob);
  }

  public formatTimeStamp(date: Timestamp | any): string {
    try {
      const dateFromTimestamp = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
      return dateFromTimestamp.toISOString().slice(0, 19);
    } catch {
      return date?.toString();
    }
  }
  public formatDate(date: string | Date): string {
    try {
      // Format to YYYY-MM-DDTHH:MM:SS
      return new Date(date).toISOString().slice(0, 19);
    } catch {
      return date?.toString();
    }
  }

  public generateKeePassXML(passwordEntries: SafePasswordDto[]): string {
    const doc = document.implementation.createDocument(null, "pwlist", null);

    const addEntryChild = (entry: HTMLElement, tag: string, val: any) => {
      var el = doc.createElement(tag);
      if (tag === 'expiretime') {
        el.setAttribute("expires", "false");
      }
      const textNode = doc.createTextNode(val);
      el.appendChild(textNode);

      entry.appendChild(el);
    };

    passwordEntries.forEach(entry => {
      const pwentry = doc.createElement("pwentry");
      addEntryChild(pwentry, "group", "General");
      addEntryChild(pwentry, "title", entry.account);
      addEntryChild(pwentry, "username", entry.username);
      addEntryChild(pwentry, "url", entry.url);
      addEntryChild(pwentry, "password", entry.password);
      addEntryChild(pwentry, "notes", entry.details);
      addEntryChild(pwentry, "notes", entry.details);
      addEntryChild(pwentry, "uuid", entry.id);
      addEntryChild(pwentry, "image", '');
      addEntryChild(pwentry, "creationtime", this.formatTimeStamp(entry.createdDate));
      addEntryChild(pwentry, "lastmodtime", this.formatDate(entry.updatedDate));
      addEntryChild(pwentry, "lastaccesstime", this.formatDate(entry.updatedDate));
      addEntryChild(pwentry, "expiretime", this.formatDate(new Date(9999, 1, 1)));

      doc.documentElement.appendChild(pwentry);
    });

    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(doc);
    return xmlFormat(xmlString, { strictMode: true, collapseContent: true });


    //let xmlEntries = passwordEntries.map(entry => `
    //  <pwentry>
    //    <group>General</group>
    //    <title>${escapeXml(entry.account)}</title>
    //    <username>${escapeXml(entry.username)}</username>
    //    <url>${escapeXml(entry.url)}</url>
    //    <password>${escapeXml(entry.password)}</password>
    //    <notes>${escapeXml(entry.details)}</notes>
    //    <uuid>${entry.id}</uuid>
    //    <image></image>
    //    <creationtime>${this.formatTimeStamp(entry.createdDate)}</creationtime>
    //    <lastmodtime>${this.formatDate(entry.updatedDate)}</lastmodtime>
    //    <lastaccesstime>${this.formatDate(entry.updatedDate)}</lastaccesstime>
    //    <expiretime expires="false">${this.formatDate(new Date(9999, 1, 1))}</expiretime>
    //  </pwentry>
    //`).join('');
    //
    //return `<?xml version="1.0" encoding="UTF-8"?>
    //  <pwlist>
    //  ${xmlEntries}
    //  </pwlist>`;
  }


  public async import(file: Blob, masterKey: string): Promise<number> {
    const keyFunc = (_: SafePasswordDto) =>
      (_.account ?? "").toLocaleLowerCase().trim() +
      "_" +
      (_.username ?? "").toLocaleLowerCase().trim();

    var passGroup = await this.getSafepasswords(true);

    let refDict = groupBy(passGroup.data ?? [], keyFunc);

    const passwords = JSON.parse(
      await file.text()
      /*, (key, value) => {
  
        if (key === nameof<SafePasswordDto>(_ => _.createdDate)
          || key === nameof<SafePasswordDto>(_ => _.updatedDate
          )) {
          if (value.seconds && value.nanoseconds) {
  
            console.log('parse date', value, new Timestamp(value.seconds, value.nanoseconds).toDate())
          }
          return new Date(value);
        }
        return value;
      }*/
    ) as SafePasswordImportDto[];

    let impRef = groupBy(passwords, keyFunc);

    const newDataList = new Array<SafePasswordDto>();

    let changeCount = 0;

    each(
      keys(refDict).filter((_) => !ld.hasIn(impRef, _)),
      (key) => {
        let dbp = refDict[key][0];
        newDataList.push(dbp);
      }
    );

    each(
      keys(impRef).filter((_) => ld.hasIn(refDict, _)),
      (key) => {
        let imp = impRef[key][0];

        if (imp.password) {
          if (!(imp.notPlain === true)) {
            imp.salt = imp.salt ?? appInstance.generateSalt();
            imp.password = appInstance.encrypt(
              imp.password,
              masterKey,
              imp.salt
            );
          }
        }

        let dbp = refDict[key][0];

        const { notPlain, id, updatedDate, createdDate, ...impCheckProps } =
          imp;
        const {
          id: db_id,
          updatedDate: db_updatedDate,
          createdDate: db_createdDate,
          ...dbpCheckProps
        } = dbp;

        if (!isEqual(impCheckProps, dbpCheckProps)) {
          let updatedPass = Object.assign(
            dbp,
            impCheckProps
          ) as SafePasswordDto;
          updatedPass.createdDate = !updatedPass.createdDate
            ? new Date()
            : updatedPass.createdDate;
          updatedPass.updatedDate = !updatedPass.updatedDate
            ? new Date()
            : updatedPass.updatedDate;
          newDataList.push(updatedPass);
          changeCount += 1;
        } else {
          newDataList.push(dbp);
        }
      }
    );

    each(
      keys(impRef).filter((_) => !ld.hasIn(refDict, _)),
      (key) => {
        let p = impRef[key][0];
        p.id = uuidv4();
        p.createdDate = !p.createdDate ? new Date() : p.createdDate;
        p.updatedDate = !p.updatedDate ? p.createdDate : p.updatedDate;
        if (p.password) {
          if (!(p.notPlain === true)) {
            p.salt = appInstance.generateSalt();
            p.password = appInstance.encrypt(p.password, masterKey, p.salt);
          }
        }

        newDataList.push(p);
        changeCount += 1;
      }
    );

    passGroup.data = newDataList;

    await this.saveOrUpdate(passGroup);

    return Promise.resolve(changeCount);
  }
}

export const passService = new PassService();
