import React, { useState } from "react";
import { AppRoutes } from "./routes/Routers";
import { Footer } from "./pages/components/Footer";
import { ToastContainer } from "react-toastify";
import Header from "./pages/components/Header";
import { NavMenu } from "./pages/components/NavMenu";
import { useAppAuth } from "./shared/hooks/auth";
import "./scss/App.scss";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { isAuthenticated } = useAppAuth();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-nowrap flex-grow-1 justify-content-start align-content-stretch align-items-stretch">
        <div className="d-flex flex-row align-items-center px-1 py-1 app-header">
          <Header open={open} setOpen={(x) => setOpen(x)} />
        </div>
        <div className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-start align-content-stretch align-items-stretch">
          {isAuthenticated && (
            <div
              className={
                "w-25 px-1 border border-1 app-menu " +
                (open ? "open-menu" : "closed-menu")
              }>
              <NavMenu open={open} />
            </div>
          )}
          <div className="flex-grow-1 d-flex flex-column flex-nowrap justify-content-start px-3 py-2 my-1">
            <AppRoutes />
          </div>
        </div>
        <div className="app-footer">
          <Footer />
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        className="fs-5"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};
export default App;
