import { useState, useEffect } from 'react'

export const enum BsBreakPoint { "xxl", "xl", "lg", "md", "sm", "xs" }

const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(getBreakpoint());

    const resizeEvent = () => {
        setBreakpoint(getBreakpoint())
    }

    useEffect(() => {
        window.addEventListener('resize', resizeEvent);
        return () => {
            window.removeEventListener('resize', resizeEvent);
        };
    }, [])

    return breakpoint;
}

const getBreakpoint = (): BsBreakPoint => {

    if (typeof window !== 'undefined') {
        if (window.matchMedia('(min-width: 1400px)').matches) {
            return BsBreakPoint.xxl;
        } else if (window.matchMedia('(min-width: 1200px)').matches) {
            return BsBreakPoint.xl;
        } else if (window.matchMedia('(min-width: 992px)').matches) {
            return BsBreakPoint.lg;
        } else if (window.matchMedia('(min-width: 768px)').matches) {
            return BsBreakPoint.md;
        } else if (window.matchMedia('(min-width: 576px)').matches) {
            return BsBreakPoint.sm;
        } else {
            return BsBreakPoint.xs;
        }
    } else {
        return BsBreakPoint.md
    }
}

export default useBreakpoint;