import React from 'react'; 

export interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => { 
    return (
        <div>
            <p>hasanuzun.com...</p> 
        </div>
    );
};

export default HomePage;
