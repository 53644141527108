import React from "react";
import { InfoCircle, ShieldCheck } from "react-bootstrap-icons";
import { Link } from "react-router-dom"; 
import { langugesList } from "../../i18n/strings";
import { useLocalize } from "../../shared/hooks/localize";

export const Footer = () => {
  const { language, setLanguage, strings, localizePath } = useLocalize();

  const handleLanguageChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    var selectedLang = event.target.value as string;
    setLanguage(selectedLang);
  };

  return (
    <footer className="d-flex justify-content-between align-items-center p-1 fs-6 ">
      <div className="d-none d-sm-block">
        {strings.footer.copyright}
        {new Date().getFullYear()}
      </div>
      <div className="d-flex flex-grow-1 justify-content-end align-items-center  p-0 m-0">
        <div
          title={strings.menu.privacy}
          className="d-flex justify-content-start align-items-center border border-1 my-0 py-0 me-1 p-1">
          <Link to={localizePath("/privacy")}>
            <ShieldCheck className="me-1" />
            <span className="d-none d-sm-inline-block">
              {strings.menu.privacy}
            </span>
          </Link>
        </div>
        <div
          title={strings.menu.imprint}
          className="d-flex justify-content-start align-items-center border border-1 my-0 py-0 me-1 p-1">
          <Link to={localizePath("/imprint")}>
            <InfoCircle className="me-1" />
            <span className="d-none d-sm-inline-block">
              {strings.menu.imprint}
            </span>
          </Link>
        </div>
        <div>
          <select
            value={language}
            className="me-1"
            onChange={handleLanguageChange}>
            {langugesList.map((lang) => {
              return (
                <option key={lang.key} value={lang.key}>
                  {lang.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </footer>
  );
};
