
import { appInstance } from "../shared/configs";
import { collection, doc, getDocs, where, query, addDoc, Timestamp, updateDoc, deleteDoc } from 'firebase/firestore';
import { MOTPSecret } from "../shared/models";

class MOTPService {


  collectionName = "motp_secrets";

  constructor() {
  }

  async getSecrets(): Promise<MOTPSecret[]> {
    const secretsRef = collection(appInstance.db, this.collectionName);
    const q = query(secretsRef, where('owner', '==', appInstance.auth.currentUser?.email));
    const snapshot = await getDocs(q);

    const secrets: MOTPSecret[] = [];
    snapshot.forEach((doc) => {
      const data = doc.data() as MOTPSecret;
      secrets.push({
        id: doc.id,
        owner: data.owner,
        name: data.name,
        secret: appInstance.decryptSimple(data.secret),
        modifiedDate: data.modifiedDate,
        createdDate: data.createdDate,
      });
    });
    return secrets;
  }

  async addSecret(name: string, secret: string): Promise<void> {
    const secretData: Omit<MOTPSecret, 'id'> = {
      owner: appInstance.auth.currentUser?.email ?? " none",
      name,
      secret: appInstance.encryptSimple(secret),
      modifiedDate: Timestamp.now(),
      createdDate: Timestamp.now(),
    };

    const secretsRef = collection(appInstance.db, this.collectionName);
    await addDoc(secretsRef, secretData);
  } 

  async updateSecret(id: string, newName: string, newSecret: string): Promise<void> {
    const secretRef = doc(appInstance.db, this.collectionName, id);
    await updateDoc(secretRef, {
      name: newName,
      secret: appInstance.encryptSimple(newSecret),
      modifiedDate: Timestamp.now(),
    });
  }

  async deleteSecret(id: string): Promise<void> {
    const secretRef = doc(appInstance.db, this.collectionName, id);
    await deleteDoc(secretRef)
  }
}

export const mOTPService = new MOTPService();