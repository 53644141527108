import { IAppStrings } from "./stringsDef";

export const trStrings: IAppStrings = {
  app: {
    title: "HUCOM SafePass",
  },
  menu: {
    home: "Anasayfa",
    email: "Email",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Gizlilik",
    imprint: "Künye",
    passwords: "Şifreler",
  },
  account: {
    signin: "Giriş yap",
    signout: "Çıkış yap",
    processingToken: "Kullanıcı bilgileri yükleniyor...",
    processingSignout: "Kullanıcı oturumu sonlandırılıyor...",
    loginTitle: "Kullanıcı Girişi",
    email: "E-Mail",
    emailorUsername: "E-Mail veya Kullanıcı Adı",
    emailRequired: "E-Mail boş bırakılamaz!",
    passwordRequired: "Şifre boş bırakılamaz!",
    password: "Şifre",
    signInViaGoogle: "Google ile giriş yap",
    signInViaFacebook: "Facebook ile giriş yap",
    signInViaMicrosoft: "Microsoft ile giriş yap",
    wrongPassOrUsername: "Kullanıcı adı/email veya şifre hatalı!",
  },
  pass: {
    noItem: "Hiç şifre bulunamadı!",
    account: "Hesap",
    url: "URL",
    createdDate: "Kayıt tarihi",
    updatedDate: "Son güncelleme tarihi",
    confirmDelete: "Silmek istediğinizden emin misiniz",
    addNewPassword: "Yeni Şifre",
    details: "Notlar",
    password: "Şifre",
    masterPassword: "Ana parola",
    username: "Kullanıcı adı",
    downloadPassList: "Indir",
    uploadPassList: "Yükle",
    importedPassMessage: "Imported password count: {0}",
    wrongMasterpass: "Hatalı parola!",
    changeMasterpass: "Parolayı değiştir",
    noUpdateOnImport: "Aktarma sırasında hiçbir değişiklik algılanmadı!"
  },
  footer: {
    copyright: "Bütün hakları saklıdır ®",
  },
  shared: {
    copy: "Kopyala",
    show: "Göster",
    hide: "Gizle",
    add: "Ekle",
    or: "ya da",
    save: "Kaydet",
    cancel: "İptal",
    delete: "Sil",
    edit: "Düzenle",
    enter: "Gir",
    import: "Dışarı aktar",
    export: "İçeri aktar", 
    keepassExport:"İçeri aktar (Keepass)",
    csvExport: "İçeri aktar (CSV)",
    underConstruction: "Yapım aşamasında!",
    technicalError:
      "Teknik bir hata oluştu, lütfen daha sonra tekrar deneyiniz!",
  },
};
