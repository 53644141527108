import React, { useState } from "react";
import { Eye, EyeSlash, X } from "react-bootstrap-icons";

export const PasswordField = (props: any) => {
  const [passVisible, setPassVisible] = useState(false);

  const { value, editable, className, ...rest } = props;

  const setVisible = () => {
    setPassVisible(true);

    if (!editable) {
      setTimeout(() => setPassVisible(false), 1500);
    }
  };

  return (
    <div className="m-0 p-0 d-flex flex-row align-items-center">
      {editable && (
        <input
          type={passVisible ? "input" : "password"}
          value={value}
          className={className + " flex-grow-1 pe-4"}
          {...rest}
        />
      )}

      {!editable && (
        <span className="flex-grow-1 pe-1">
          {passVisible
            ? value
            : value?.length > 0
            ? "*".repeat(value.length)
            : ""}
        </span>
      )}

      {!passVisible && <Eye className="ms-1 fs-4" onClick={() => setVisible()} />}
      {passVisible && (
        <EyeSlash className="ms-1 fs-4" onClick={() => setPassVisible(false)} />
      )}
    </div>
  );
};
