import React from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const CustomMenuToggle = React.forwardRef<
  HTMLSpanElement,
  { onClick: (e: React.MouseEvent<SVGElement, MouseEvent>) => void }
>(({ onClick }, ref) => (
  <span ref={ref} role="button">
    <ThreeDotsVertical
      className="fs-1"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  </span>
));

export default CustomMenuToggle;
