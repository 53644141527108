import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ApplicationPaths, QueryParameterNames } from "./../shared/configs";
import { useAppAuth } from "../shared/hooks/auth";
import { useLocalize } from "../shared/hooks/localize";

export const AuthorizeRoute = (props: any) => {
  const { isAuthenticated } = useAppAuth();
  const { localizePath } = useLocalize();
  let location = useLocation();

  const { component: Component, ...rest } = props;

  if (isAuthenticated) {
    return <Component {...rest} />;
  } else {
    const returnUrl = `${location.pathname}${location.search}${location.hash}`;
    const redirectUrl = `${localizePath(ApplicationPaths.Login)}?${QueryParameterNames.ReturnUrl
      }=${encodeURIComponent(returnUrl)}`;
    return <Navigate to={redirectUrl} />;
  }
};

export default AuthorizeRoute; 
