import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { At, BoxArrowRight } from "react-bootstrap-icons"; 
import { useAppAuth } from "../../shared/hooks/auth";
import { useLocalize } from "../../shared/hooks/localize";

export const LoginHeader = () => {
  const { user, isAuthenticated, logout } = useAppAuth();
  const { strings } = useLocalize();

  if (!isAuthenticated) {
    return <React.Fragment />;
  }

  return (
    <DropdownButton id="user-menu" title={user?.displayName ?? user?.email}>
      <Dropdown.ItemText className="d-none d-sm-block">
        <At className="pe-1 nav-menu-icon" title={user?.email ?? ""} />
        <span className="cut-text">{user?.email}</span>
      </Dropdown.ItemText>
      <Dropdown.Item as="button" onClick={logout}>
        <BoxArrowRight
          className="pe-1 nav-menu-icon"
          title={strings.account.signout}
        />
        {strings.account.signout}
      </Dropdown.Item>
    </DropdownButton>
  );
};
