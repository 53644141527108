import React from "react";
import { Pagination } from "react-bootstrap";
import useBreakpoint, { BsBreakPoint } from "../hooks/useBreakpoint";

export interface PaginationProps {
  totalCount: number;
  selectedPage: number;
  pagerSize: number;
  onChange: (page: number) => void;
}

const getRelativeMargins = (breakPoint: BsBreakPoint) => {
  if (breakPoint === BsBreakPoint.xxl) {
    return 14;
  } else if (breakPoint === BsBreakPoint.xl) {
    return 12;
  } else if (breakPoint === BsBreakPoint.lg) {
    return 10;
  } else if (breakPoint === BsBreakPoint.md) {
    return 8;
  } else if (breakPoint === BsBreakPoint.sm) {
    return 5;
  } else {
    return 2;
  }
};

const CustomPagination = (props: PaginationProps) => {
  const { totalCount, selectedPage, pagerSize, onChange } = props;
  const breakPoint = useBreakpoint();

  const pageCount = Math.floor(
    totalCount ? (totalCount + pagerSize - 1) / pagerSize : 0
  );

  if (pageCount < 2 || selectedPage < 1) {
    return <></>;
  }

  const pageNumTitle = (p: number) => {
    return `${(p - 1) * pagerSize + 1}-${p * pagerSize} / ${totalCount}`;
  };

  const displayNumberMargins = getRelativeMargins(breakPoint);
  const items = new Array<any>();
  let leftMin =
    displayNumberMargins >= selectedPage
      ? 1
      : selectedPage - displayNumberMargins;
  let rightMax = selectedPage + displayNumberMargins;

  const fixMargins = () => {
    leftMin =
      leftMin < selectedPage ? (leftMin <= 0 ? 1 : leftMin) : selectedPage;
    rightMax = rightMax < pageCount ? rightMax : pageCount;
  };

  fixMargins();

  if (selectedPage - leftMin < displayNumberMargins) {
    rightMax += displayNumberMargins - (selectedPage - leftMin);
  } else if (rightMax - selectedPage < displayNumberMargins) {
    leftMin -= displayNumberMargins - (rightMax - selectedPage);
  }

  fixMargins();

  if (leftMin > 2) {
    items.push(
      <Pagination.First
        key="f1"
        title={pageNumTitle(1)}
        onClick={() => onChange(1)}
      />
    );

    items.push(
      <Pagination.Prev
        key={"p" + (selectedPage - 1)}
        title={pageNumTitle(selectedPage - 1)}
        onClick={() => onChange(selectedPage - 1)}
      />
    );
  } else if (leftMin === 2) {
    items.push(
      <Pagination.Item
        title={pageNumTitle(1)}
        onClick={() => onChange(1)}
        key={1}>
        {1}
      </Pagination.Item>
    );
  }

  if (leftMin > 2) {
    items.push(
      <Pagination.Item
        title={pageNumTitle(leftMin - 1)}
        onClick={() => onChange(leftMin - 1)}
        key={leftMin - 1}>
        {`${1}..${leftMin - 1}`}
      </Pagination.Item>
    );
  }

  for (
    let number = leftMin;
    number <= rightMax && number <= pageCount;
    number++
  ) {
    if (number > 0) {
      items.push(
        <Pagination.Item
          title={pageNumTitle(number)}
          onClick={() => onChange(number)}
          key={number}
          active={number === selectedPage}>
          {number}
        </Pagination.Item>
      );
    }
  }

  if (rightMax < pageCount - 1) {
    items.push(
      <Pagination.Item
        title={pageNumTitle(rightMax + 1)}
        onClick={() => onChange(rightMax + 1)}
        key={rightMax + 1}>
        {`${rightMax + 1}..${pageCount}`}
      </Pagination.Item>
    );
  }

  if (selectedPage < pageCount && rightMax < pageCount) {
    if (selectedPage === pageCount - 1) {
      <Pagination.Item
        title={pageNumTitle(pageCount)}
        onClick={() => onChange(pageCount)}
        key={pageCount}>
        {pageCount}
      </Pagination.Item>;
    } else {
      items.push(
        <Pagination.Next
          key={"n" + selectedPage + 1}
          title={pageNumTitle(selectedPage + 1)}
          onClick={() => onChange(selectedPage + 1)}
        />
      );
    }
  }

  if (selectedPage < pageCount - 1 && rightMax < pageCount) {
    items.push(
      <Pagination.Last
        key={"l" + pageCount}
        title={pageNumTitle(pageCount)}
        onClick={() => onChange(pageCount)}
      />
    );
  }

  return (
    <Pagination className="py-0 my-0">
      {items}
      {/*  <Pagination.First />
        <Pagination.Prev />

       
        <Pagination.Item>{1}</Pagination.Item>
        <Pagination.Ellipsis />

        <Pagination.Item>{10}</Pagination.Item>
        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item active>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>
        <Pagination.Item disabled>{14}</Pagination.Item>

        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>
        <Pagination.Next />
        <Pagination.Last /> */}
    </Pagination>
  );
};

export default CustomPagination;
