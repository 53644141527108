import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthorizeRoute from "./AuthorizeRoute";

import { navigationItems } from "../common/navigationConfig";
import { useAppAuth } from "../shared/hooks/auth";
import { useLocalize } from "../shared/hooks/localize";
import { ApplicationPaths } from "../shared/configs";
import { langugesList } from "../i18n/strings";

export const AppRoutes = () => {
  const { isAuthenticated } = useAppAuth();
  const { localizePath, language } = useLocalize();

  return (
    <Routes>
      {navigationItems
        .filter((x) => x.path && x.component)
        .map((item, index) => {
          const component =
            item.requireAuth === true ? (
              <AuthorizeRoute component={() => item.component} />
            ) : (
              item.component
            );
          return langugesList.map((l) => {
            return (
              <Route
                key={"route" + index}
                path={`/${l.key}${item.path}`}
                element={component}
              />
            );
          });
          /*  
                                    return (
                                        <Route
                                            key={"route" + index}
                                            path={`/:urlLang${item.path}`}
                                            element={component}
                                        />
                                    ); */
        })}
      <Route path="*" element={<Navigate to={localizePath("/")} />} />
      {/*
            <Route path="/" element={<AuthorizeRoute component={HomePage} />} />

        <Route path="/pass" element={<AuthorizeRoute component={PassPage} />} />
<Route path="/login" element={<LoginPage />} />

<Route
    path="*"
    element={<Navigate to="/" />}
/>
</Routes> */}
    </Routes>
  );
};
