import { AES, enc, lib, PBKDF2 } from "crypto-js";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from "firebase/firestore";

function parseBoolean(value?: string | number | boolean | null) {
  value = value?.toString().toLowerCase();
  return value === "true" || value === "1";
}

const devEnv: DevEnv = process.env;
const appEnv: AppEnv = process.env;
// process.env.NODE_ENV === "development" ? process.env : window._env_;

const authCallbackPrefix = "/auth";

export const ApplicationPaths = {
  AuthCallbackPrefix: authCallbackPrefix,
  ApiAuthorizationPrefix: authCallbackPrefix,
  Login: "/login",
  LoginCallback: `${authCallbackPrefix}/signin`,
  LoginSilentCallback: `${authCallbackPrefix}/signin/silent`,
  LogOutCallback: `${authCallbackPrefix}/signout`,
};

export const appConfig = {
  appUrl: window.location.origin,
  version: appEnv.REACT_APP_version,
  loginPath: ApplicationPaths.Login,
  forbiddenPath: "/forbidden",
  environment: devEnv.NODE_ENV,
  fb_useEmulators: devEnv.REACT_APP_use_emulators,
  fb_apiKey: appEnv.REACT_APP_apiKey,
  fb_authDomain: appEnv.REACT_APP_authDomain,
  fb_databaseURL: appEnv.REACT_APP_databaseURL,
  fb_projectId: appEnv.REACT_APP_projectId,
  fb_storageBucket: appEnv.REACT_APP_storageBucket,
  fb_messagingSenderId: appEnv.REACT_APP_messagingSenderId,
  fb_measurementId: appEnv.REACT_APP_measurementId,
  fb_appId: appEnv.REACT_APP_appId,
};

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const appRootUrl = window.location.origin;

export const getReturnUrl = (state?: any): string => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    // throw new Error(
    //  "Invalid return url. The return url needs to have the same origin as the current page."
    //);
  }
  return (
    (state && state.returnUrl) || fromQuery || "/" // `${window.location.origin}/`
  );
};

export class AppInstance {
  public app: FirebaseApp = {} as FirebaseApp;
  public db: Firestore = {} as Firestore;
  public auth: Auth = {} as Auth;

  private iv = enc.Utf8.parse("WRFjG1ie");
  private appSimpleEncKey;

  initialize = () => {
    this.appSimpleEncKey = this.generateKey("12341254", "qNsREiiO/S5X3zf3gl4YnQ==");
    const useEmulators = parseBoolean(appConfig.fb_useEmulators);

    const firebaseOptions = {
      apiKey: useEmulators ? "fakeApiKey" : appConfig.fb_apiKey,
      authDomain: useEmulators ? "localhost" : appConfig.fb_authDomain,
      databaseURL: appConfig.fb_databaseURL,
      appId: appConfig.fb_appId,
      measurementId: appConfig.fb_measurementId,
      projectId: appConfig.fb_projectId,
      messagingSenderId: appConfig.fb_messagingSenderId,
      storageBucket: appConfig.fb_storageBucket,
    } as FirebaseOptions;

    this.app = initializeApp(firebaseOptions);

    this.db = getFirestore(this.app);

    this.auth = getAuth();

    if (useEmulators) {
      connectAuthEmulator(
        this.auth,
        `http://localhost:${process.env.REACT_APP_emulatorAuthPort}`,
        { disableWarnings: true }
      );

      // Connect the Firestore SDK to the local emulator
      connectFirestoreEmulator(
        this.db,
        "localhost",
        parseInt(process.env.REACT_APP_emulatorFirestorePort ?? "8080")
      );

      if (appConfig.environment === "development") {
        console.log("Firebase initialized", useEmulators);
      }
    }
  };
  generateSalt = () => {
    return lib.WordArray.random(16).toString(enc.Base64);
  };

  generateKey = (masterKey: string, salt: string) => {
    return PBKDF2(masterKey, enc.Base64.parse(salt));
  };

  encrypt = (input: string, masterKey: string, salt: string): string => {
    return AES.encrypt(input, this.generateKey(masterKey, salt), {
      iv: this.iv,
    }).toString();
  };

  decrypt = (input: string, masterKey: string, salt: string): string => {
    return AES.decrypt(input, this.generateKey(masterKey, salt), {
      iv: this.iv,
    }).toString(enc.Utf8);
  };

  encryptSimple = (input: string): string => {
    return AES.encrypt(input, this.appSimpleEncKey, {
      iv: this.iv,
    }).toString();
  };

  decryptSimple = (input: string): string => {
    return AES.decrypt(input, this.appSimpleEncKey, {
      iv: this.iv,
    }).toString(enc.Utf8);
  };
}

export const appInstance = new AppInstance();
