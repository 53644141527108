import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { Link } from "react-router-dom"; 
import { useAppAuth } from "../../shared/hooks/auth";
import { useLocalize } from "../../shared/hooks/localize";
import { LoginHeader } from "./LoginHeader";

const Header = (props: { open: boolean; setOpen: (x: boolean) => void }) => {
  const { isAuthenticated } = useAppAuth();
  const { localizePath } = useLocalize();
  const { open, setOpen } = props;

  return (
    <React.Fragment>
      {isAuthenticated && (
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          role="button"
          className="closed-menu">
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip>{open ? "Close" : "Open"}</Tooltip>}>
            <span onClick={() => setOpen(!open)}>
              <List size="24" color="blue" />
            </span>
          </OverlayTrigger>
        </div>
      )}
      <div className="pe-1">
        <Link to={localizePath("/")}>
          <img
            className="pe-1"
            src={process.env.PUBLIC_URL + "/favicon-32x32.png"}
            alt="HUCOM Suite"
          />
          <span>HUCOM Suite</span>
        </Link>
      </div>
      {isAuthenticated && (
        <div className="ms-auto p-1">
          <LoginHeader />
        </div>
      )}
    </React.Fragment>
  );
};
export default Header;
