import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { trimEndTrailingSlashes } from "../../common/utils";
import strings, { defaultLang, langugesList } from "../../i18n/strings";
import { IAppStrings } from "../../i18n/stringsDef";

export interface ILocalizeContext {
  language: string;
  strings: IAppStrings;
  setLanguage: (lng: string) => void;
  localizePath: (path: string) => string;
}

export const LocalizeContext = React.createContext<ILocalizeContext>({
  language: defaultLang,
  strings: {} as IAppStrings,
  setLanguage: (lng: string) => undefined,
  localizePath: (path: string) => path,
});

const findCurrentLang = (urlLang?: string, cookieLang?: string): string => {
  return urlLang ?? cookieLang ?? defaultLang;
};

const localizePath = (path: string, lang: string) => {
  return trimEndTrailingSlashes(`/${lang}${path}`) ?? path;
}

export const localizeContext = React.createContext<ILocalizeContext>({} as ILocalizeContext);

const langCookieAttrs: CookieSetOptions = { path: "/", sameSite: "none", httpOnly: false, secure: true };

export const LocalizeProvider = (props: any) => {
  const [langCookie, setLangCookie] = useCookies(["lang"]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { lang } = langCookie;

  const [language, setLanguage] = useState(
    findCurrentLang(lang, defaultLang)
  );

  useEffect(() => {

    const hasLangInUrl = langugesList.findIndex(x => pathname.startsWith("/" + x.key)) > -1

    if (!hasLangInUrl) {
      setLangCookie("lang", defaultLang, langCookieAttrs);
      strings.setLanguage(defaultLang);
      setLanguage(defaultLang);
      navigate(localizePath("/", defaultLang), { replace: true });
    } else {

      setLangCookie("lang", language, langCookieAttrs);
      strings.setLanguage(language);
    }
  }, [language, navigate, pathname, setLangCookie]
  );

  return (
    <localizeContext.Provider value={{
      language: language,
      strings: strings,
      setLanguage: (lng: string) => {
        if (language === lng) {
          return;
        }

        let path = pathname.replace("/" + language, "/" + lng);

        setLanguage(lng);
        setLangCookie("lang", language, langCookieAttrs);

        navigate(trimEndTrailingSlashes(path) ?? localizePath("/", lng), { replace: true });
      },
      localizePath: (path: string) => {
        return localizePath(path, language) ?? path;
      },
    }}>
      {props.children}
    </localizeContext.Provider>
  );
};


export const useLocalize = (): ILocalizeContext => {
  const context = React.useContext(localizeContext);

  if (context === undefined) {
    throw new Error("useLocalize must be used within a LocalizeProvider");
  }
  return context;
};
