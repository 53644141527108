import { IAppStrings } from "./stringsDef";

export const enStrings: IAppStrings = {
  app: {
    title: "HUCOM SafePass",
  },
  menu: {
    home: "Home",
    email: "Email",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Privacy",
    imprint: "Imprint",
    passwords: "Passwords",
  },
  account: {
    signin: "Sign in",
    signout: "Sign out",
    processingToken: "User session is being initialized...",
    processingSignout: "User session is being closed...",
    loginTitle: "Login",
    email: "E-Mail",
    emailorUsername: "E-Mail oder Username",
    password: "Password",
    emailRequired: "E-Mail is required!",
    passwordRequired: "Password is required!",
    signInViaGoogle: "Sign in by Google",
    signInViaFacebook: "Sign in by Facebook",
    signInViaMicrosoft: "Sign in by Microsoft",
    wrongPassOrUsername: "Username/email or password is wrong!",
  },
  pass: {
    noItem: "There is no password!",
    account: "Account",
    url: "Url",
    createdDate: "Creation date",
    updatedDate: "Last updated date",
    confirmDelete: "Are you sure to delete the password",
    addNewPassword: "New password",
    details: "Details",
    password: "Password",
    masterPassword: "Master password",
    username: "Username",
    downloadPassList: "Download",
    uploadPassList: "Upload",
    importedPassMessage: "Imported password count: {0}",
    wrongMasterpass: "Wrong master password!",
    changeMasterpass: "Change master password",
    noUpdateOnImport: "No changes detected on import!"
  },
  footer: {
    copyright: "All rights reserved ®",
  },
  shared: {
    copy: "Copy",
    show: "Show",
    hide: "Hide",
    add: "Add",
    or: "or",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
    edit: "Edit",
    enter: "Enter",
    import: "Import",
    export: "Export",
    keepassExport:"Export (Keepass)",
    csvExport: "Export (CSV)",
    underConstruction: "Under construction!",
    technicalError: "An error occurred, please try again later!",
  },
};
